import { Link } from 'react-router-dom';
import React from 'react';

import './Home.css';
import { HomeInterface } from './Home.interface';

export const Home: React.FC<HomeInterface> = (props) => {
  let links: Array<JSX.Element> = [];

  props.links.forEach((link) => {
    links.push(
      <li>
        <Link to={link.path}>{link.title}</Link>
      </li>
    );
  });

  return (
    <nav className='menu'>
      <ul>{links}</ul>
    </nav>
  );
};
