import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import 'swagger-ui-react/swagger-ui.css';

import { bucket } from '../config/config';
import { Home } from '../Home';
import { SwaggerViewerInterface } from './SwaggerViewer.interface';

export const SwaggerViewer: React.FC<SwaggerViewerInterface> = (props) => {
    let files: Array<JSX.Element> = [];
    let links: Array<{path: string, title: string}> = [];

    props.files.forEach((file, index) => {
        const url = !!file.manuallyDoc ? `/swagger-manually/${file.fileName}` : `${bucket}/swagger/${file.fileName}`;
        files.push(
            <Route key={index} path={file.path}>
                <SwaggerUI url={url} />
            </Route>
        );
        links.push({ path: file.path, title: file.title });
    });

    return (
        <Router>
            <Switch>
                {files}
                <Route path='/'>
                    <Home links={links}></Home>
                </Route>
            </Switch>
        </Router>
    );
}